.header {
  width: 100%;
  background: #fff;
  padding: 15px 30px;
  box-shadow: 0px 2px 14px 7px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 99;
  .logo {
    max-width: 175px;
    img {
      width: 100%;
    }
  }
  
  .menu {
    
    min-width: 500px;
    :global {
      
      .ant-menu-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-inline: 32px;
        gap: 2px;
        font-family: 'Roboto Medium';
        color: #989898;
        &::after {
          display: none;
        }
        &:hover {
          color: var(--primary-color) !important;
        }
        
        .material-symbols-outlined {
          display: flex;
          font-size: 25px;
          width: 25px;
          height: 25px;
        }
      }
      .desktop-icon:hover {
        color:var(--primary-color) !important;
        stroke: #0991f2 !important;
        svg path {
          stroke:#0991f2
        }
      }
      
      .ant-menu-item-active {
        color: var(--primary-color);
        &::after {
          display: none;
        }
      }
      .ant-menu-title-content {
        margin-inline-start: 0px !important;
        line-height: 20px;
      }
    }
    .menu-items {
      width: 100%;
      align-items: center;
      justify-content: center;
      border: none;
      
    }
  }
  .avatar-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    p {
      text-align: right;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      color: #414141;
      line-height: normal;
      span {
        display: block;
        color: #aaa;
        font-size: 15px;
      }
    }
  }
}

.dropdown {
  ul {
    width: 150px;
    float: right;
    margin-top: 10px;
  }
  .icon {
    font-size: 17px !important;
  }
}
