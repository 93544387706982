body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiSvgIcon-root {
  font-size: 25px !important;
}

@font-face {
  font-family: "Roboto Thin";
  src: url("./assets/fonts/Roboto-Bold.eot");
  src: url("./assets/fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Roboto-Bold.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Bold.woff") format("woff"),
    url("./assets/fonts/Roboto-Bold.ttf") format("truetype"),
    url("./assets/fonts/Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Light";
  src: url("./assets/fonts/Roboto-Light.eot");
  src: url("./assets/fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Roboto-Light.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Light.woff") format("woff"),
    url("./assets/fonts/Roboto-Light.ttf") format("truetype"),
    url("./assets/fonts/Roboto-Light.svg#Roboto-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Medium";
  src: url("./assets/fonts/Roboto-Medium.eot");
  src: url("./assets/fonts/Roboto-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Roboto-Medium.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Medium.woff") format("woff"),
    url("./assets/fonts/Roboto-Medium.ttf") format("truetype"),
    url("./assets/fonts/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Regular";
  src: url("./assets/fonts/Roboto-Regular.eot");
  src: url("./assets/fonts/Roboto-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Roboto-Regular.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Regular.woff") format("woff"),
    url("./assets/fonts/Roboto-Regular.ttf") format("truetype"),
    url("./assets/fonts/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Thin";
  src: url("./assets/fonts/Roboto-Thin.eot");
  src: url("./assets/fonts/Roboto-Thin.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Roboto-Thin.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Thin.woff") format("woff"),
    url("./assets/fonts/Roboto-Thin.ttf") format("truetype"),
    url("./assets/fonts/Roboto-Thin.svg#Roboto-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hubballi";
  src: url("./assets/fonts/Hubballi-Regular.eot");
  src: url("./assets/fonts/Hubballi-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Hubballi-Regular.woff2") format("woff2"),
    url("./assets/fonts/Hubballi-Regular.woff") format("woff"),
    url("./assets/fonts/Hubballi-Regular.ttf") format("truetype"),
    url("./assets/fonts/Hubballi-Regular.svg#Hubballi-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: "Material Symbols";
  src: url("./assets/fonts/MaterialSymbolsOutlined.woff2") format("woff2"),
    url("./assets/fonts/MaterialSymbolsOutlined.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */
