.card-wrapper {
  width: 400px;
}

.reset-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  :global {
    .ant-btn-icon {
      margin-inline-end: 0 !important;
    }
  }
}

.app-name {
  font-weight: 600;
  font-size: 14px;
  color: var(--primary-color);
}