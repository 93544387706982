.section {
  position: relative;
  .heading {
    margin-bottom: 27px;
    display: flex;
    justify-content: space-between;
    h1, h2 {
      color: #c0c0c0;
      font-size: 34px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      flex: 1;
      b,
      strong {
        font-weight: 700;
      }
    }
    .extra {
      flex: 0
    }
  }
}
