:root{
  /* ---- Fonts ---- */
  --primary-font:                        "Roboto", sans-serif;
  --secondry-font:                       "Hubballi", sans-serif;
  /* ---- Main colors ---- */
  --primary-color:                       #0991f2;
  --white-background:                    #efefef;
  --secondary-color:                     rgba(13, 17, 23, 0.75);
  --button-color:                        #0991f2;
  --text-color:                          #efefef;
  --content-color:                       #656565;
  --page-section-heading-color:          #525252;

  /* ---- Background ---- */
  --background-gradient:                 linear-gradient(to right, rgb(255 153 0 / 10%), #C9D6FF)
}