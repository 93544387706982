.upload {
  width: 100%;
  :global {
    span.ant-upload {
      // border: 1px dashed #54adff !important;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%2354ADFFFF' stroke-width='3' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    }
    .ant-upload {
      width: 100% !important;
      min-height: 200px;
      border-radius: 5px !important;
      border: none !important;
      background: #fff;
      display: flex;
      flex-direction: column;
      gap: 25px;
      color: #909090;
    }
  }
  :global(.ant-upload-disabled) {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.image-preview {
  width: 100%;
  position: relative;
  img, video {
    width: 100%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%2354ADFFFF' stroke-width='3' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    padding: 2px;
  }
  button {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 0;
    height: 20px;
    border-radius: 0;
    border: none;
    background: none;
    z-index: 99;
    background: #000;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    color: #fff;
    line-height: 26px;
  }
}
