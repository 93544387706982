.button-wrapper {
  button {
    display: flex;
    align-items: center;
  }
}

.search-input {
  width: 350px;
  margin-bottom: 20px;
}

.submit-button {
  float: right;
  width: auto;
}