.select {
  :global {
    .ant-select-selector {
      padding-inline-start: 11px;
      padding-top: 6px;
      padding-bottom: 6px;
    }
    .ant-select-selection-placeholder {
      line-height: 48px !important;
    }
  }
  &:global(.ant-select-single) {
    height: 50px;
    :global(.ant-select-selector) {
      height: 100% !important;
    }
    :global(.ant-select-selection-item) {
      line-height: 48px !important;
    }
    :global(.ant-select-selection-search) {
      :global(.ant-select-selection-search-input) {
        height: 50px;
      }
    }
  }
  &:global(.ant-select-multiple) {
    min-height: 50px;
    :global(.ant-select-selector) {
      min-height: 50px;
    }
    :global(.ant-select-selection-item) {
      line-height: 24px !important;
      background: var(--primary-color);
      color: #fff;
      :global(.anticon) {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}
