.info-wrapper {
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  background: #fff;
  box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
  padding: 14px 24px;
  max-width: 625px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 20px;
  .icon {
    img {
      max-width: 119px;
    }
  }
  .text {
    text-align: center;
    min-width: 288px;
    & > div {
      display: flex;
      align-items: center;
      gap: 11px;
      p {
        color: #000;

        text-align: right;
        font-family: var(--secondry-font);
        font-size: 24px;
        font-weight: 400;
      }
      img {
        max-width: 161px;
      }
    }
    p {
      color: #656565;
      text-align: center;
      font-size: 11px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
  .action {
    display: flex;
    align-self: center;
    gap: 20px;
    .btn {
      width: 126px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      :global {
        .ant-btn-icon {
          margin-inline-start: -5px;
        }
      }
    }
  }
}
