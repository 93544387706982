.wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
  min-height: calc(100vh - 176px);
  .apps-area {
    flex: 1;
  }
  .delete-btn {
    width: auto;
  }
  .card-wrapper {
    :global {
      .ant-card-actions {
        li {
          button {
            span {
              flex: unset;
            }
          }
        }
      }
    }
  }
  .create-button {
    border: 1px dashed var(--primary-color);
    background: none !important;
    box-shadow: none !important;
    color: var(--primary-color);
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 45px 15px;
    outline: none;
    box-shadow: none;
    &:hover {
      background: none;
      color: var(--primary-color) !important;
    }
    .space {
      row-gap: 0px;
    }
  }
}


.add-icon {
  font-size: 30px !important;
}