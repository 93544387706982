.search-input {
  background: #f0f0f0;
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
  &:global(.ant-input-affix-wrapper) {
    padding: 9px 11px;
    input {
      background: #f0f0f0;
    }
  }
  .icon {
    background: var(--primary-color);
    width: 30px;
    height: 30px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}