.card {
  box-shadow: 0 8px 15px 5px rgba(0,0,0,0.06);
  :global {
    .ant-card-head {
      border-bottom: none;
      min-height: unset;
      padding-block-start: 24px;
      color: var(--primary-color);
      font-family: 'Roboto Regular';
      font-size: 18px;
    }
    .ant-card-body {
      padding: 18px 24px;
    }
  }
  &.transparent {
    box-shadow: none;
    border: none;
    border-radius: 0;
    :global {
      .ant-card-head {
        padding-block-start: 0;
      }
    }
  }
}