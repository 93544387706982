.login-success-wrapper {
  height: calc(100vh - 76px);
  margin: -50px -30px;
  display: flex;
  align-items: center;
  justify-content: center;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
