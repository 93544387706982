html,
body {
  height: 100%;
  font-family: "Roboto", sans-serif;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.loader {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.material-symbols-outlined {
  font-family: "Material Symbols";
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}


.ant-btn-default {
  color: var(--primary-color);
  border-color: var(--primary-color);
}