@import "../../styles/_breakpoints.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(to right, rgb(255 153 0 / 10%), #c9d6ff);
  padding: 0 20px;

  .wrapper-container {
    display: flex;
    width: 900px;
    margin: 20px 0;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    @media screen and (max-width: $mobile) {
      flex-direction: column;
      box-shadow: none;
    }
  }

  .left-section {
    background-color: var(--white-background);
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 10px 0px 0px 10px;
    padding: 20px;

    @media screen and (max-width: $mobile) {
      background: none;
      padding: 0;
    }

    .logoResponsiveMobile {
      display: none;
      height: auto;

      @media screen and (max-width: $mobile) {
        margin: 0 auto;
        margin-bottom: 20px;
        display: block;
      }
    }
    .octoHelps{
      @media screen and (max-width: $mobile) {
        margin-left: 90px;
      }

    }

    .welcome-wrapper {
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 15px;

      @media screen and (max-width: $mobile) {
        display: none;
      }

      .title-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: "Hubballi";
      }

      .sub-title {
        font-size: 32px;
        margin: 0px;
      }

      .title {
        font-size: 36px;
        margin: 0px;
      }
    }
  }

  .right-section {
    background-color: var(--secondary-color);
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 0px 10px 10px 0px;
    padding: 40px 20px 52px 20px;
    align-items: center;
    justify-content: center;
    position: relative;

    @media screen and (max-width: $mobile) {
      border-radius: 10px;
    }

    .back-arrow {
      color: white;
      position: absolute;
      left: 20px;
      top: 48px;
      font-size: 20px;
    }

    .form {
      width: 85%;
    }

    .label-text {
      font-size: 15px;
      color: var(--text-color);
      margin-bottom: 0;
    }

    .sign-in {
      font-size: 24px;
      color: var(--text-color);
      margin: 0px 0px 15px 0px;
      font-weight: bold;
    }

    .login-button {
      font-weight: bold;
      width: 100%;
      margin-top: 18px;
      background-color: var(--button-color);
      border: none;
      color: var(--text-color);
      height: 40px;
      padding: 0;
    }

    .login-button-sso {
      font-weight: bold;
      width: 100%;
      margin-top: 18px;
      background-color: var(--secondary-color);
      border: none;
      color: var(--text-color);
      height: 40px;
      padding: 0;
    }

    .login-type-button {
      width: 85%;
      margin-top: 16px;
      background: none;
      border: none;
    }

    .login-type-button:hover{
      color: none !important;

    }

    .social-container {
      color: var(--text-color);
      text-align: center;
      font-size: 14px;
      margin-top: 20px;
    }

    .social-media-icons {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }

    .forgot-password {
      color: white;
      margin: 0px;
      text-align: right;
      cursor: pointer;
      display: inline-block;
      position: relative;
      left: 68%;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-background-clip: text;
      background-color: #2c2e31 !important;
      -webkit-box-shadow: 0 0 0px 1000px #2c2e31 inset;
      -webkit-text-fill-color: #ffffff;
    }

    .input-field:not(:global(.ant-input-password)),
    .input-field:global(.ant-input-password) input {
      height: 38px;
      background-color: #2c2e31 !important;
      border-color: #a6acb7 !important;
      color: var(--text-color);
      caret-color: var(--text-color);
    }

    .input-field:global(.ant-input-password) {
      padding: 0 11px 0 0;
      border-color: #a6acb7 !important;
      background-color: #2c2e31;

      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #2c2e31 inset !important;
      }

      input {
        padding: 4px 11px;
        border-radius: 6px 0 0 6px;
        caret-color: var(--text-color);
      }

      :global(.anticon.ant-input-password-icon) {
        color: var(--text-color);
      }
    }

    .input-field::placeholder {
      color: var(--text-color) !important;
    }

    .input-field:global(.ant-input-password)::placeholder,
    .input-field input[type="password"]::placeholder {
      color: var(--text-color) !important;
      background-color: unset !important;
    }

    .invalid-message {
      font-size: 16px;
      text-align: center;
      margin-top: 12px;
      color: #ff4d4f;
      margin: 0;
    }

    .ant-btn-default:not(:disabled):hover {
      color: var(--text-color) !important;
    }
    :global {

      .ant-form-item {
        margin-bottom: 10px !important;
      }
    }
  }
}

.input-addon {
  :global {
    .ant-input-affix-wrapper {
      background: #2c2e31;
      border-color: #a6acb7 !important;
      height: 38px;
      input {
        background: #2c2e31;
        color: #fff;

        &::placeholder {
          color: #fff;
        }
      }
    }

    .ant-input-group-addon {
      border-color: #a6acb7 !important;
    }

    .ant-input-suffix {
      svg {
        path {
          fill: #fff;
        }
      }
    }

    .ant-input-group-addon {
      background: #2c2e31;
    }
  }
}

.tooltip-content {
  p {
    margin: 0;
  }

  ol {
    margin: 10px 0;
    padding: 0;
    padding-left: 15px;
  }
}
