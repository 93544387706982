.card {
  border-radius: 5px;
  box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.12);
  border: none;
  cursor: pointer;
  &.disabled {
    cursor: auto;
  }
  :global {
    .ant-card-cover {
      margin-top: 0px;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      img {
        border-radius: 5px;
        object-fit: cover;
      }
    }
  }

  .tag {
    background: rgb(215,215,215);
    background:  linear-gradient(0deg, rgba(215,215,215,1) 36%, rgba(255,255,255,1) 95%);
    font-size: 14px;
    padding: 3px 14px;
    position: absolute;
    top: -14px;
    right: 0;
    border-radius: 50px;
  }

  .heading {
    display: flex;
    flex-shrink: 1;
    min-width: 0;
    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
      text-transform: uppercase;
      color: var(--content-color);
      // text-wrap: nowrap;
      // text-overflow: ellipsis;
      // overflow: hidden;
    }
    .like {
      font-size: 12px;
      color: #000;
      display: flex;
      align-items: center;
      gap: 5px;
      svg {
        path {
          fill: var(--primary-color);
        }
      }
    }
  }
  :global {
    .ant-card-actions {
      li {
        & > span {
          padding-inline-start: 10px;
          padding-inline-end: 10px;
        }
        button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          :global {
            .material-symbols-outlined {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .delete-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 28px;
  }
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 13px;
    .space {
      border-left: 1px solid rgba(215, 215, 215, 1);
      padding-left: 13px;
      button {
        width: 76px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          margin-right: 0 !important;
        }
      }
    }
  }
}